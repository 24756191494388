/* src/styles/globals.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* src/styles/typography.css */

.h1 {
  @apply font-bold leading-[22px] sm:leading-[22px] md:leading-[34px] lg:leading-[40px] xl:leading-[50px];
  font-size: clamp(30px, 5vw + 10px, 40px);
}
  .h2 {
    @apply font-bold leading-[36px] sm:leading-[42px] md:leading-[56px] lg:leading-[64px] xl:leading-[72px];
    font-size: clamp(24px, 5vw + 8px, 40px);
  }
  
.h3 {
  @apply text-[22px] sm:text-[24px] md:text-[26px] lg:text-[30px] xl:text-[30px] font-[650] leading-[24px] sm:leading-[26px] md:leading-[28px] lg:leading-[32px];
}

.h48 {
  @apply font-bold text-[22px] sm:text-[28px] lg:text-[34px] xl:text-[48px];
  font-size: clamp(22px, 4vw + 10px, 48px);
  line-height: clamp(26px, 2vw + 4px, 36px);
}

/* line height */
/* Custom line height for lg and below */
@media (max-width: 1024px) {
  .line-height-0-lg {
    line-height: 0;
  }
}

/* Add to your CSS */
.heading-7 {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

/* global.css or component CSS file */
body {
  position: relative;
  background: #F2F3F4; 
  min-height: 100vh;
  margin: 0;
}

/* ================================================ */
/* Custom Radio Button Styles */
input[type="radio"] {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid rgba(223, 228, 234, 1);
  border-radius: 50%;
  margin-right: 12px;
  position: relative;
  cursor: pointer;
  background-color: rgb(255, 255, 255);

}

input[type="radio"]:checked {
  border-color: rgba(209, 165, 84, 1);
  background-color: white;
}

input[type="radio"]:checked::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: rgba(209, 165, 84, 1);
}

input[type="radio"]:focus {
  outline: none;
  ring: 2px;
  ring-color: rgba(209, 165, 84, 1);
  ring-opacity: 0.5;
}

/* ================================================ */

/* Custom Checkbox Styles */
input[type="checkbox"] {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid rgba(223, 228, 234, 1);
  border-radius: 4px;
  margin-right: 12px;
  position: relative;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
}

input[type="checkbox"]:checked {
  border-color: rgba(209, 165, 84, 1);
  background-color: rgba(209, 165, 84, 1);
}

input[type="checkbox"]:checked::after {
  content: "";
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
}

input[type="checkbox"]:focus {
  outline: none;
  ring: 2px;
  ring-color: rgba(209, 165, 84, 1);
  ring-opacity: 0.5;
}



/* ================================================ */

/* Custom styles for react-datepicker */
.react-datepicker {
  font-family: 'Poppins', sans-serif !important;
  border-radius: 1rem !important;
  overflow: hidden;
  border: none;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  max-width: 100%;
}

.custom-datepicker {
  border-radius: 1rem !important;
  overflow: hidden;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  width: 100%;
}

/* Ensure the date picker is fully responsive on small screens */
.custom-datepicker .react-datepicker__header {
  background-color: #f9fafb;
  border-bottom: 1px solid #e5e7eb;
  padding-top: 1rem;
}

.custom-datepicker .react-datepicker__day-name,
.custom-datepicker .react-datepicker__day {
  width: 2.5rem;
  line-height: 2.5rem;
  margin: 0.2rem;
  font-size: 0.875rem;
  color: #374151;
}

/* Selected day styles */
.custom-datepicker .react-datepicker__day--selected,
.custom-datepicker .react-datepicker__day--keyboard-selected {
  background-color: rgba(209, 165, 84, 1) !important;
  color: white !important;
  border-radius: 50%;
}

.custom-datepicker .react-datepicker__day:hover {
  background-color: #F0E6D9;
  border-radius: 50%;
}

.custom-datepicker .react-datepicker__day--today {
  font-weight: bold;
  color: #C4A484;
}

/* Disabled dates */
.custom-datepicker .react-datepicker__day--disabled {
  color: #d1d5db !important;
  background-color: #f9fafb !important;
  cursor: not-allowed !important;
}

/* Popper Styles for Positioning */
.custom-datepicker .react-datepicker__triangle {
  display: none;
}

/* Popper adjustments for smaller screens */
.custom-datepicker-popper {
  z-index: 9999 !important;
  transform: translate3d(0, 52px, 0) !important;
  max-width: 100% !important;  /* Ensure popper doesn't overflow */
}

/* Responsive adjustments */
@media (max-width: 568px) {
  /* Adjust popper for mobile */
  .custom-datepicker-popper {
    transform: translate3d(0, 56px, 0) !important; /* Ensure it opens below the input */
    width: 100% !important; /* Make sure popper width fits on small screens */
  }

  .custom-datepicker .react-datepicker {
    height: auto !important; /* Prevents calendar from being too tall */
    max-width: 100% !important;
  }

  /* Adjust the width of the calendar for mobile */
  .custom-datepicker .react-datepicker__day-name,
  .custom-datepicker .react-datepicker__day {
    width: 2.25rem; /* Smaller width for mobile */
    font-size: 0.75rem; /* Smaller font size */
  }
}
/* ================================================ */


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans'; /* Apply Open Sans font */
  line-height: 1.6; /* Increases line height for better readability */
  letter-spacing: 0.5px; /* Adds slight letter spacing */
}

/* Global styles for all Swiper Pagination dots */
.swiper-pagination-bullet {
  width: 7px !important;
  height: 7px !important;
  background-color: rgb(255, 255, 255) !important; /* brandGold color */
  opacity: 1 !important;
  margin: 0 4px !important;
}

.swiper-pagination-bullet-active {
  background-color: rgba(209, 165, 84, 1) !important; /* brandGold color for active dot */
}
